import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductBag() {

    const shopifyProductId = '6128101294247'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "Adjustable": [
            "33 1/2 / 85"
        ],
        "Length": [
            "13 / 33"
        ],
        "Width": [
            "18 / 45.7"
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - Bag" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductBag